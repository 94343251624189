import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { v4 as uuidv4 } from "uuid";

import { Container, Box, Typography, InputAdornment, Radio, Grid, IconButton } from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AlarmIcon from "@mui/icons-material/Alarm";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import WavingHandIcon from "@mui/icons-material/WavingHand";
import PublishIcon from "@mui/icons-material/Publish";

import logo from "../../assets/images/wLiveLogo(Red).png";
import disabled from "assets/images/disabled.png";
import Avartar from "assets/images/Avartar";
import { Textfield } from "components/Textfield";
import { CustomLoadingButton as Button } from "components/Button";
import mainCharacter1 from "assets/images/mainCharacter1.png";
import mainCharacter2 from "assets/images/mainCharacter2.png";
import mainCharacter3 from "assets/images/mainCharacter3.png";

import { SnackBarContext } from "components/SnackBar/ContextAPI";
import { QuizUserContext, QuizUserProvider } from "./contexts/QuizUserContext";
import useInterval from "utils/useInterval";
import { divideHangul } from "utils/chosung";

// import Ava

const bgcolors = ["#F2EAA2", "#BFBCDE", "#F4CEC2", "#99CCCC", "#EDD39F", "#c26974"];

const stringToColor = (string) => {
  if (!string) return;

  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

const QuizUserPage = () => {
  return (
    <QuizUserProvider>
      <QuizUserSlide />
    </QuizUserProvider>
  );
};

const QuizUserSlide = (props) => {
  const { currentSlide } = useContext(QuizUserContext);

  return (
    <Box
      sx={{
        height: { xs: "calc(var(--vh, 1vh) * 100)", lg: "100vh" },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Container
        maxWidth="xs"
        sx={{
          height: { xs: "calc(var(--vh, 1vh) * 95)", lg: "95vh" },
          boxShadow: "2px 2px 6px #00000028",
          borderRadius: "10px",
          overflow: "auto",
          backgroundColor: "#fff",
          margin: "auto",
        }}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              boxSizing: "border-box",
              // width: { xs: "50%" },
              mx: "auto",
              pt: "10px",
              height: { xs: "calc(var(--vh, 1vh) * 8)", lg: "8vh" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "clamp(7.0875px, 2.25vw, 9.99px)",
                opacity: 0.75,
              }}
            >
              <img src={logo} alt={"logo"} style={{ width: "clamp(28.35px, 9vw, 39.96px)" }} />

              <Typography
                sx={{ font: "bold 24px Pretendard", fontSize: "clamp(17.01px, 5.4vw, 23.976px)" }}
              >
                Withplus Live - 라이브 퀴즈
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              boxSizing: "border-box",
              flex: 1,
              pb: "10px",
              height: { xs: "calc(var(--vh, 1vh) * 87)", lg: "87vh" },
            }}
          >
            {currentSlide}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export const InputNameContainer = () => {
  const { id } = useParams();
  const snackBar = useContext(SnackBarContext);
  const { socket, setCurrentSlide, handleName, setPlayingData, setImgIdx, setPlayingUsers } =
    useContext(QuizUserContext);
  const [cookies, setCookie] = useCookies();

  const [name, setName] = useState("");
  const [isButtonLoading, setButtonLoading] = useState(false);

  const editName = (e) => {
    if (e.target.value.length > 15) return;
    setName(e.target.value);
  };

  const enterUser = (e) => {
    e.preventDefault();

    if (name === "") {
      snackBar.setText("닉네임을 입력해주세요 :)");
      snackBar.setSeverity("warning");
      snackBar.setOpen(true);
      return;
    }

    setButtonLoading(true);

    if (!cookies.uid) {
      const uid = uuidv4();

      setCookie("uid", uid, { path: "/" });
      socket.emit("enterUser", { type: "LiveQuiz", name: name, id: id, userId: uid });
    } else {
      socket.emit("enterUser", { type: "LiveQuiz", name: name, id: id, userId: cookies.uid });
    }
  };

  const onKeyPress = (e) => {
    if (e.key !== "Enter") return;

    e.target.blur();
  };

  useEffect(() => {
    if (!socket) return;
    if (!cookies.uid) return;

    socket.emit("getQuizData", { quizID: id }, ({ quiz }) => {
      const playingUsers = quiz.playingUsers;
      console.log(playingUsers);
      const user = playingUsers.filter((user) => user.uid + "" === cookies.uid)[0];

      console.log(user);

      setPlayingData(quiz.playingData);
      setPlayingUsers(playingUsers);

      if (!user) return;
      handleName(user.name);
      setImgIdx(user.imgIdx);

      if (quiz.isQuizStarted) {
        // * 현재 진행중인 퀴즈로 이동
        setCurrentSlide(<QuizContainer />);
      } else {
        setCurrentSlide(<WaitingSlide />);
      }
    });
  }, [socket]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Box>
        <Typography sx={{ font: "bold 24px NotoSansKR-Medium", mb: "10px" }}>
          닉네임을 입력해주세요!
        </Typography>

        <Typography sx={{ font: "18px NotoSansKR-Medium", mb: "20px" }}>(최대 15자)</Typography>

        <Box component="form" onSubmit={enterUser}>
          <Textfield
            sx={{ mb: "20px" }}
            value={name}
            onChange={editName}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start" children={<p>{15 - name.length} </p>} />
              ),
            }}
            placeholder="닉네임을 입력해주세요."
            fullWidth
            onKeyPress={onKeyPress}
          />

          <Button
            size="large"
            sx={{
              font: "700 20px Pretendard",
              bgcolor: "#d6e2fb",
              color: "#50586C",
              "&:hover": { bgcolor: "#c6d2eb" },
              boxShadow: "1px 4px 4px rgba(0, 0, 0, 0.25)",
            }}
            onClick={enterUser}
            loading={isButtonLoading}
          >
            <WavingHandIcon sx={{ mr: "10px" }} />
            입장하기
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export const WaitingSlide = () => {
  const { name, imgIdx } = useContext(QuizUserContext);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        // alignItems: "center",
        pt: "40px",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          // p: "20px",
          // pt: "30px",
        }}
      >
        {/* <CheckCircleIcon
          sx={{
            fontSize: "100px",
            color: "#00ff00",
            "@keyframes floating": {
              "0%": { transform: "translate(0,0)" },
              "100%": { transform: "translate(0, -20px)" },
            },
            animation: "floating .75s infinite alternate",
          }}
        /> */}
        {imgIdx !== null && (
          <Box
            sx={{
              position: "relative",
              mb: "20px",
            }}
          >
            <img
              src={Avartar[imgIdx]}
              style={{
                // width: "clamp(92px, 29.27vw, 130px)",
                // height: "clamp(92px, 29.27vw, 130px)",
                width: "180px",
                height: "180px",
                filter: "drop-shadow(0px 3px 6px #00000038)",
              }}
            />
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: "10px",
            flexDirection: "column",
          }}
        >
          {/* <Avatar
            sx={{
              mr: "5px",
              width: "24px",
              height: "24px",
              fontSize: "16px",
              backgroundColor: stringToColor(name),
            }}
          >
            {name && name[0].toUpperCase()}
          </Avatar> */}

          <Box sx={{ position: "relative", mb: "20px" }}>
            <Typography
              sx={{
                font: "900 30px Pretendard",
                wordBreak: "break-all",
                "> span": { fontWeight: 600, fontSize: "27px" },
              }}
            >
              {name} <span>님</span>
            </Typography>

            {/* <IconButton
              sx={{
                position: "absolute",
                top: 0,
                right: -40,
                bgcolor: "#bbb",
                "&:hover": {
                  bgcolor: "#ccc",
                },
              }}
              size="small"
              onClick={() => console.log("hello")}
            >
              <EditIcon />
            </IconButton> */}
          </Box>

          <Typography sx={{ font: "700 24px Pretendard", wordBreak: "break-all" }}>
            퀴즈 입장이 완료되었습니다!
          </Typography>
        </Box>

        <Box sx={{ my: "30px" }}>
          <Typography sx={{ font: "600 20px Pretendard", fontSize: "clamp(14px, 4.5vw, 20px)" }}>
            강사 화면에서 닉네임을 확인 후, <br />
            강사가 퀴즈 게임을 시작하기 전까지 대기해주세요.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const QuizContainer = () => {
  const { id } = useParams();
  const [cookies] = useCookies();
  const {
    slideList,
    slideIndex,
    isStarted,
    setStarted,
    setResultOpened,
    setAnswerOpened,
    score,
    setScore,
    timer,
    setTimer,
    selectedAnswers,
    setCurrentScore,
    name,
    setPlayingData,
    setSelectedAnswers,
    isSubmitted,
    setSubmitted,
    setRank,
    setTimeOver,
    socket,
    currentContainer,
    setCurrentContainer,
    setPlayingUsers,
    isAllQuizOver,
    setIsAllQuizOver,
  } = useContext(QuizUserContext);
  const SnackBar = useContext(SnackBarContext);

  const [submitTime, setSubmitTime] = useState(null);

  const submitAnswer = () => {
    if (selectedAnswers.length === 0) return;
    if (isSubmitted) return;

    setSubmitted(true);
    setSubmitTime(slideList[slideIndex].timer - timer);
    socket.emit("submitAnswer", {
      id: id,
      selectedAnswers: selectedAnswers,
      uid: cookies.uid,
      name,
      time: slideList[slideIndex].timer - timer,
      type: slideList[slideIndex].type,
    });
  };

  useEffect(() => {
    if (!socket) return;

    socket.emit("getQuizData", { quizID: id }, ({ quiz }) => {
      const users = quiz.playingUsers;
      const playingData = quiz.playingData;

      const user = users.filter((user) => user.uid + "" === cookies.uid)[0];
      const myData = playingData.submittedAnswers.filter(
        (item) => item.uid + "" === cookies.uid
      )[0];

      users.sort((a, b) => {
        return b.score - a.score;
      });

      setPlayingData(quiz.playingData);
      setPlayingUsers(users);
      setScore(user.score);
      setSelectedAnswers(myData?.selectedAnswers || []);
      setTimer(playingData.slideList[playingData.slideIndex].timer);

      if (playingData.isStarted) {
        setStarted(true);
      }

      if (myData?.selectedAnswers.length > 0) {
        setSubmitted(true);
      }

      if (playingData.isTimeOver) {
        setTimeOver(true);
        setTimer(0);
      }

      if (quiz.playingData.isAnswerOpened) {
        setAnswerOpened(true);
      }
      if (quiz.playingData.isResultOpened) {
        setResultOpened(true);
        setCurrentContainer(<MidResultContainer />);
        setCurrentScore(myData?.score || 0);
      }
      if (quiz.playingData.isAllQuizOver) {
        var index = users.findIndex((user) => user.uid + "" === cookies.uid);
        setRank(index + 1);
        setCurrentContainer(<QuizResultContainer />);
        setIsAllQuizOver(true);
      }
    });

    socket.on("submitAnswer", (data) => {
      if (data.success) {
        SnackBar.setText("제출 완료!");
        SnackBar.setSeverity("success");
        SnackBar.setOpen(true);
        SnackBar.setOrigin({ vertical: "top", horizontal: "center" });
      }
    });

    return () => {
      socket.off("submitAnswer");
    };
  }, [socket]);

  useEffect(() => {
    if (timer === 0) {
      const ranNum = Math.floor(Math.random() * 500) + 10;

      setTimeout(() => {
        socket.emit("submitAnswer", {
          id: id,
          selectedAnswers: selectedAnswers,
          uid: cookies.uid,
          // time: slideList[slideIndex].timer,
          time: submitTime || slideList[slideIndex].timer,
        });
      }, ranNum);
      // socket.emit("submitAnswer", {
      //   id: id,
      //   selectedAnswers: selectedAnswers,
      //   uid: cookies.uid,
      //   time: slideList[slideIndex].timer,
      // });
    }
  }, [timer, submitTime]);

  useEffect(() => {
    if (!isStarted) {
      setSubmitTime(null);
    }
  }, [isStarted]);

  return (
    <Box sx={{ height: "100%", position: "relative", display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          // position: "absolute",
          height: "100%",
          width: "100%",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          // justifyContent: "flex-end",
          // overflow: "auto",
        }}
      >
        <Box
          sx={{
            mb: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ font: "600 18px Pretendard", "> span": { font: "800 20px Pretendard" } }}
          >
            점수 : <span>{score}</span> 점
          </Typography>

          <Typography sx={{ font: "600 18px Pretendard" }}>
            {slideIndex + 1}번째 문제 / {slideList.length}문제
          </Typography>
        </Box>
        <Box sx={{ mb: "10px", display: "flex", alignItems: "center" }}>
          <Box sx={{ flex: 1, mr: "5px" }}>
            <Box
              sx={{
                width: "100%",
                bgcolor: "#aaaaaa",
                height: "10px",
                borderRadius: "10px",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  height: "10px",
                  bgcolor: timer / slideList[slideIndex].timer > 0.5 ? "#3f5bae" : "#c33c3c",
                  width: `${(timer / slideList[slideIndex].timer) * 100}%`,
                  borderRadius: "10px",
                  transition: "width 0.25s linear",
                }}
              />
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <AlarmIcon sx={{ mr: "5px", fontSize: "26px", color: "#5050ff" }} />

            <Typography sx={{ fontWeight: "600" }}>
              {slideList[slideIndex].timer === 0 ? "제한없음" : `${Math.ceil(timer)}초`}
            </Typography>
          </Box>
        </Box>
        {currentContainer}
      </Box>
      <Box sx={{ mt: "5px", display: isAllQuizOver ? "none" : "flex", justifyContent: "center" }}>
        <Button
          size="large"
          disabled={!isStarted || timer === 0 || isSubmitted}
          onClick={submitAnswer}
          sx={{
            font: "700 20px Pretendard",
            bgcolor: "#d6e2fb",
            color: "#50586C",
            "&:hover": { bgcolor: "#d6e2fb" },
            "&:active": { bgcolor: "#d6e2fb", transform: "translateY(4px)" },
            boxShadow: "1px 4px 4px rgba(0, 0, 0, 0.25)",
          }}
        >
          <PublishIcon sx={{ mr: "10px" }} />
          제출하기
        </Button>
      </Box>
    </Box>
  );
};

export const QuizSlideContainer = ({ timer }) => {
  const { id } = useParams();
  const { isStarted, slideIndex, slideList } = useContext(QuizUserContext);

  const [waitingTimer, setWaitingTimer] = useState(5);
  const [image, setImage] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (waitingTimer === 0) {
        clearInterval(interval);
        return;
      }
      setWaitingTimer((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [waitingTimer]);

  useEffect(() => {
    if (!slideList) return;
    if (slideList.length === 0) return;

    const currentSlide = slideList[slideIndex];

    if (currentSlide.mediaName) {
      const img = new Image();

      img.src = `https://quiz.withplus.live/image?slide-id=${currentSlide.id}&type=cropped&game-id=${id}`;

      img.onload = () => {
        setImage(img);
      };

      img.onerror = () => {
        setImage(null);
      };
    }
  }, [slideList]);

  return (
    <Box
      sx={{
        // overflow: "auto",
        // overflow: isStarted ? "auto" : "hidden",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "relative",
        // bgcolor: "Red",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          height: "100%",
          width: "100%",
          bgcolor: "#f5f5f5",
          borderRadius: "10px",
          zIndex: 1,
          display: isStarted ? "none" : "flex",
          // display: "flex",
          // justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          p: "10px",
          boxSizing: "border-box",
          gap: "10px",
        }}
      >
        <Typography sx={{ font: "bold 27px Pretendard" }}>QUIZ.{slideIndex + 1}</Typography>

        <Typography
          sx={{ font: "bold 24px Pretendard", color: "#555" }}
        >{`[${slideList[slideIndex].type}]`}</Typography>

        <Typography sx={{ font: "900 27px Pretendard" }}>
          {slideList[slideIndex].question}
        </Typography>

        <Typography sx={{ font: "800 24px Pretendard", color: "#77f" }}>
          {`[${slideList[slideIndex].score}점]`}
        </Typography>

        <Box sx={{ width: "100%", mt: "20px" }}>
          <Typography sx={{ font: "700 75px Pretendard", color: "#666" }}>
            {waitingTimer === 0 ? "시작!" : waitingTimer}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          py: "5px",
          borderBottom: "1px solid #cdcdcd",
          boxSizing: "border-box",
        }}
      >
        <Typography sx={{ fontWeight: "500", "> span": { color: "#666" } }}>
          {slideList && slideList[slideIndex].question}
          <span>{slideList[slideIndex].isMultiple === "true" ? " (복수 정답)" : ""}</span>
        </Typography>
      </Box>

      {slideList[slideIndex].mediaType && image && (
        <Box sx={{ height: "150px", p: "10px", boxSizing: "border-box" }}>
          <img src={image.src} style={{ objectFit: "contain", height: "100%" }} />
        </Box>
      )}

      <Box
        sx={{
          boxSizing: "border-box",
          flex: 1,
          // borderRadius: "8px",
          mt: "5px",
          // p: "10px",
          // height: "100%",
          bgcolor: slideList[slideIndex]?.type === "초성 퀴즈" ? "" : "#f5f5f5",
          display: "flex",
          flexWrap: "wrap",

          // overflowY: "auto",
        }}
      >
        {(() => {
          switch (slideList[slideIndex]?.type) {
            case "객관식":
              return <SelectAnswerContent />;
            case "OX 퀴즈":
              return <OXAnswerContent />;
            case "초성 퀴즈":
              return <InitialAnswerContent />;
            default:
              return <></>;
          }
        })()}
      </Box>
    </Box>
  );
};

const InitialAnswerContent = () => {
  const {
    slideIndex,
    slideList,
    isAnswerOpened,
    selectedAnswers,
    setSelectedAnswers,
    isSubmitted,
    timer,
  } = useContext(QuizUserContext);

  const [initials, setInitials] = useState([]);

  useEffect(() => {
    if (!slideList) return;
    if (!slideList[slideIndex]) return;

    const answer = slideList[slideIndex].answer;
    let newInitials = [];

    for (let i = 0; i < answer.length; i++) {
      newInitials.push(divideHangul(answer[i]));
    }

    if (newInitials.join("") === initials.join("")) return;

    setInitials(newInitials);
  }, [slideIndex, slideList]);

  return (
    <Box sx={{ width: "100%" }}>
      {isAnswerOpened && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            textAlign: "center",
            mb: "10px",
          }}
        >
          <Typography sx={{ font: "800 30px Pretendard" }}>정답은</Typography>

          <Typography
            sx={{
              font: "900 30px Pretendard",
              border: "2px solid #eee",
              borderRadius: "8px",
              p: "10px",
              bgcolor: "#00b890",
              color: "#fff",
              textShadow: "2px 2px 2px rgba(0, 0, 0, 0.5)",
            }}
          >
            {slideList[slideIndex].answer}
          </Typography>
        </Box>
      )}

      {!isAnswerOpened && (
        <Box sx={{ display: "flex", justifyContent: "center", gap: "10px", flexWrap: "wrap" }}>
          {initials.map((item, idx) => (
            <Typography
              sx={{
                font: "900 21px Pretendard",
                mb: "10px",
                border: "2px solid #aaa",
                color: "#333",
                p: item === " " ? "0px" : "7.5px",
                borderRadius: "10px",
                boxSizing: "border-box",
                opacity: item === " " ? "0" : "1",
                boxShadow:
                  "inset 0 -2px 0 #aaaaaa, inset 0px 1px 1px -1px #fff, 0px 1px 1px 0px #7a7a7a;",
              }}
              key={idx}
            >
              {item}
            </Typography>
          ))}
        </Box>
      )}
      <Textfield
        fullWidth
        placeholder="정답을 입력해주세요."
        disabled={timer === 0 || isSubmitted}
        value={selectedAnswers[0] || ""}
        onChange={(e) => setSelectedAnswers([e.target.value])}
        InputProps={{
          endAdornment: isAnswerOpened && (
            <InputAdornment position="end" sx={{ alignItems: "center" }}>
              {selectedAnswers[0]?.replace(" ", "") ===
              slideList[slideIndex].answer?.replace(" ", "") ? (
                <CheckCircleIcon sx={{ color: "#00ff00" }} />
              ) : (
                <ClearIcon sx={{ color: "#ff0000" }} />
              )}
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

const SelectAnswerContent = () => {
  const {
    slideIndex,
    slideList,
    isAnswerOpened,
    selectedAnswers,
    setSelectedAnswers,
    isSubmitted,
    timer,
  } = useContext(QuizUserContext);

  const selectAnswer = (e) => {
    if (timer === 0 || isSubmitted) return;

    if (slideList[slideIndex].isMultiple === "true") {
      if (selectedAnswers.includes(e.currentTarget.id)) {
        setSelectedAnswers(selectedAnswers.filter((answer) => answer !== e.currentTarget.id));
      } else {
        setSelectedAnswers([...selectedAnswers, e.currentTarget.id]);
      }
    } else {
      if (selectedAnswers.includes(e.currentTarget.id)) {
        setSelectedAnswers(selectedAnswers.filter((answer) => answer !== e.currentTarget.id));
      } else {
        setSelectedAnswers([e.currentTarget.id]);
      }
    }
  };

  return (
    <Box sx={{ height: "100%", width: "100%", display: "flex", flexWrap: "wrap" }}>
      {slideList[slideIndex]?.answers &&
        slideList[slideIndex]?.answers.map((answer, idx) => (
          <SelectAnswerOption answer={answer} idx={idx} key={answer.id} />
        ))}
      {slideList[slideIndex]?.answers.length % 2 !== 0 && (
        <Box
          sx={{
            width: "50%",
            minHeight: "100px",
            backgroundImage: `url(${disabled})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            opacity: 0.5,
          }}
        />
      )}
    </Box>
  );
};

const SelectAnswerOption = ({ answer, idx }) => {
  const {
    slideIndex,
    slideList,
    isAnswerOpened,
    selectedAnswers,
    timer,
    isSubmitted,
    setSelectedAnswers,
  } = useContext(QuizUserContext);

  const boxRef = useRef(null);
  const textRef = useRef(null);

  useEffect(() => {
    if (!boxRef.current || !textRef.current) return;

    const boxHeight = boxRef.current.clientHeight;
    const textHeight = textRef.current.clientHeight;

    if (textHeight > boxHeight - 50) {
      textRef.current.style.transform = `scale(${(boxHeight - 50) / textHeight}) translateX(5px)`;
    } else {
      textRef.current.style.transform = `scale(1)`;
    }
  }, [answer]);

  // useEffect(() => {
  //   if (!question_p.current || !question_box.current || !currentSlide) return;

  //   const questionHeight = question_p.current.clientHeight;
  //   const questionBoxHeight = question_box.current.clientHeight;

  //   if (questionHeight > questionBoxHeight) {
  //     question_p.current.style.transform = `scale(${questionBoxHeight / questionHeight})`;
  //   } else {
  //     question_p.current.style.transform = `scale(1)`;
  //   }
  // }, [currentSlide.question]);

  const selectAnswer = (e) => {
    if (timer === 0 || isSubmitted) return;

    if (slideList[slideIndex].isMultiple === "true") {
      if (selectedAnswers.includes(e.currentTarget.id)) {
        setSelectedAnswers(selectedAnswers.filter((answer) => answer !== e.currentTarget.id));
      } else {
        setSelectedAnswers([...selectedAnswers, e.currentTarget.id]);
      }
    } else {
      if (selectedAnswers.includes(e.currentTarget.id)) {
        setSelectedAnswers(selectedAnswers.filter((answer) => answer !== e.currentTarget.id));
      } else {
        setSelectedAnswers([e.currentTarget.id]);
      }
    }
  };
  return (
    <Box
      sx={{ width: "50%", p: "2px", boxSizing: "border-box", minHeight: "100px" }}
      key={answer.id}
    >
      <Box
        ref={boxRef}
        sx={{
          bgcolor: bgcolors[idx % bgcolors.length],
          // width: "50%",
          cursor: "pointer",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // minHeight: "100px",
          // p: "20px 0 20px 35px",
          p: "10px",
          minHeight: "100%",
          flexShrink: 0,
          boxSizing: "border-box",
          // height: "50px",
          // display: "flex",
          // alignItems: "center",
          // bgcolor: selectedAnswers.includes(answer.id) ? "#f0f0f0" : "#f5f5f5",
          border:
            isAnswerOpened && slideList[slideIndex].correctAnswerIDs.includes(answer.id)
              ? "5px solid #00ff00"
              : "",
          // my: "5px",
          // borderRadius: "10px",
          // px: "10px",
          // py: "10px",
          // mr: "10px",
          // boxShadow: selectedAnswers.includes(answer.id)
          //   ? "inset 2px 2px 2px rgba(0, 0, 0, 0.05), inset -2px -2px 2px rgba(255, 255, 255, 0.5), 3px 3px 3px rgba(255, 255, 255, 0.5), -3px -3px 3px rgba(0, 0, 0, 0.05)"
          //   : "2px 2px 2px rgba(0, 0, 0, 0.05), -2px -2px 2px rgba(255, 255, 255, 0.5), inset 3px 3px 3px rgba(255, 255, 255, 0.5), inset -3px -3px 3px rgba(0, 0, 0, 0.05)",
        }}
        id={answer.id}
        onClick={selectAnswer}
      >
        {/* <Radio checked={selectedAnswers.includes(answer.id)} /> */}

        <Typography
          sx={{
            color: "#fff",
            font: "900 24px Pretendard",
            fontStyle: "italic",
            position: "absolute",
            top: 5,
            left: 5,
            textShadow: "2px 2px 2px rgba(0, 0, 0, 0.5)",
          }}
        >
          {idx + 1}.
        </Typography>

        <Typography
          ref={textRef}
          sx={{
            color: "#fff",
            font: "bold 24px Pretendard",
            textShadow: "1px 1px 1px rgba(0,0,0,0.6)",
            // bgcolor: "red",
          }}
        >
          {answer.text}
        </Typography>

        {selectedAnswers.includes(answer.id) && (
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              bgcolor: "#00000030",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              top: 0,
              left: 0,
            }}
          >
            <CheckIcon sx={{ color: "#c33c3c", fontSize: "80px" }} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

const OXAnswerContent = () => {
  const {
    slideIndex,
    slideList,
    isAnswerOpened,
    selectedAnswers,
    setSelectedAnswers,
    isSubmitted,
    timer,
  } = useContext(QuizUserContext);

  const selectAnswer = (e) => {
    if (timer === 0 || isSubmitted) return;

    setSelectedAnswers(e.currentTarget.id);
  };

  return (
    <Grid container sx={{ height: "100%" }}>
      <Grid item xs={6} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Box
          sx={{
            width: "70%",
            aspectRatio: " 4/3 auto",
            border: 4,
            borderColor: selectedAnswers === "O" ? "#c33c3c" : "#cdcdcd",
            bgcolor: isAnswerOpened && slideList[slideIndex].answer === "O" ? "#00ff00" : "",
            borderRadius: "16px",
            boxShadow: "3px 3px 6px #00000050",
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
            alignItems: "center",
            position: "relative",
          }}
          id="O"
          onClick={selectAnswer}
        >
          <PanoramaFishEyeIcon sx={{ fontSize: "56px", color: "red" }} />
        </Box>
      </Grid>

      <Grid item xs={6} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Box
          sx={{
            width: "70%",
            aspectRatio: " 4/3 auto",
            border: 4,
            borderColor: selectedAnswers === "X" ? "#c33c3c" : "#cdcdcd",
            bgcolor: isAnswerOpened && slideList[slideIndex].answer === "X" ? "#00ff00" : "",
            borderRadius: "16px",
            boxShadow: "3px 3px 6px #00000050",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            position: "relative",
          }}
          id="X"
          onClick={selectAnswer}
        >
          <ClearIcon sx={{ fontSize: "56px", color: "blue" }} />
        </Box>
      </Grid>
    </Grid>
  );
};

export const MidResultContainer = () => {
  const [cookies] = useCookies();
  const { currentScore, slideList, slideIndex, score, beforeRank, playingUsers, setBeforeRank } =
    useContext(QuizUserContext);

  const [isImgLoaded, setIsImgLoaded] = useState(false);
  const [updatingScore, setUpdatingScore] = useState(score - currentScore);
  const [waitingTime, setWaitingTime] = useState(0);
  const [randomIndex, setRandomIndex] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [rankingArray, setRankingArray] = useState([]);
  const [rankingPx, setRankingPx] = useState(`-${50 * (beforeRank - 1)}px`);

  useInterval(() => {
    if (currentScore === 0 || updatingScore === score || waitingTime !== 1000) return;

    setUpdatingScore((prev) => prev + 1);
  }, 1);

  useInterval(() => {
    if (waitingTime === 1000) return;

    setWaitingTime((prev) => prev + 10);
  }, 10);

  useEffect(() => {
    const maxScore = slideList[slideIndex].score;
    const percentage = maxScore === 0 ? 0 : Math.floor((currentScore / maxScore) * 50);
    const sortedUsers = playingUsers.sort((a, b) => b.score - a.score);
    const currentRank = sortedUsers.findIndex((user) => user.uid + "" === cookies.uid) + 1;

    setRandomIndex(Math.floor(Math.random() * 3));
    setRankingArray(sortedUsers);
    setRankingPx(`-${50 * (beforeRank - 1)}px`);

    setTimeout(() => {
      setPercentage(percentage);
    }, 10);

    setTimeout(() => {
      setRankingPx(`-${50 * (currentRank - 1)}px`);
      setBeforeRank(currentRank);
      sessionStorage.setItem("beforeRank", currentRank);
    }, 2000);
  }, []);

  return (
    <Box sx={{ flex: 1, bgcolor: "#f5f5f5", borderRadius: "10px", position: "relative" }}>
      <Box
        sx={{
          height: "100%",
          // position: "absolute",
          // top: "50%",
          // left: "50%",
          // transform: "translate(-50%, -50%)",
          bgcolor:
            currentScore > 0
              ? "#00b890"
              : !slideList[slideIndex].score
              ? "rgb(214, 226, 251)"
              : "#ff4949",
          // bgcolor: "rgb(214, 226, 251)",
          borderRadius: "10px",
          // backdropFilter: "blur(10px)",
          // boxShadow:
          //   "35px 35px 68px 0px #f5f5f580, inset -4px -4px 16px 0px #f5f5f599, inset 0px 11px 28px 0px #ffffff",
          // width: "75%",
          // aspectRatio: "1/1 auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            visibility: isImgLoaded ? "visible" : "hidden",
          }}
        >
          <Box sx={{ position: "relative" }}>
            <img
              src={currentScore > 0 ? mainCharacter1 : mainCharacter2}
              alt="mainCharacter"
              onLoad={() => setIsImgLoaded(true)}
              style={{ width: "33%" }}
            />
            {/* {currentScore > 0 ? (
                <img src={mainCharacter1} alt="mainCharacter" style={{ width: "33%" }} />
              ) : (
                <img src={mainCharacter2} alt="mainCharacter" style={{ width: "33%" }} />
              )} */}

            <Box
              sx={{
                position: "absolute",
                // top: -30,
                top: -40,
                right: "50%",
                transform: "translate(50%, -50%)",
                bgcolor: "#fff",
                width: "230px",
                height: "40px",
                borderRadius: "5px",
                padding: "15px",
                filter: "drop-shadow(3px 3px 6px #00000058)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#333",
                ":after": {
                  borderTop: "10px solid #fff",
                  borderLeft: "10px solid transparent",
                  borderRight: "10px solid transparent",
                  content: '""',
                  borderBottom: "10px solid transparent",
                  position: "absolute",
                  top: "100%",
                  right: "50%",
                  transform: "translateX(50%)",
                },
              }}
            >
              {(() => {
                if (currentScore > 0) {
                  return (
                    <Typography sx={{ font: "600 24px Pretendard" }}>
                      {(() => {
                        switch (randomIndex) {
                          case 0:
                            return "좀 하는데..?";
                          case 1:
                            return "이걸 맞혀..?";
                          case 2:
                            return "오.. 제법인데..";
                          default:
                            return "좀 하는데..?";
                        }
                      })()}
                    </Typography>
                  );
                } else if (slideList[slideIndex].score * 1 === 0) {
                  return (
                    <Typography sx={{ font: "600 24px Pretendard", wordBreak: "keep-all" }}>
                      아니, 이 문제에는 점수가 없다고?
                    </Typography>
                  );
                } else {
                  return (
                    <Typography sx={{ font: "600 24px Pretendard" }}>
                      {(() => {
                        switch (randomIndex) {
                          case 0:
                            return "이걸 못 맞혀..?";
                          case 1:
                            return "아쉽다..";
                          case 2:
                            return "에헤이.. 이건 맞혀야지";
                          default:
                            return "이걸 못 맞혀..?";
                        }
                      })()}
                    </Typography>
                  );
                }
              })()}
            </Box>
          </Box>
          {/* <EmojiEventsIcon sx={{ color: "gold", fontSize: "70px" }} /> */}

          <Typography
            sx={{
              font: "800 39px Pretendard",
              color: "#fff",
              textShadow: "2px 2px 2px rgba(0, 0, 0, 0.5)",
            }}
          >
            {currentScore > 0 ? `${currentScore}점 획득!` : "0점"}
          </Typography>
        </Box>

        <Box
          sx={{
            bgcolor: "#fff",
            // p: "10px",
            width: "calc(100% - 20px)",
            // boxSizing: "border-box",
            borderRadius: "10px",
            mt: "20px",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            boxShadow: "3px 3px 6px #00000058",
          }}
        >
          <Box
            sx={{
              bgcolor: "#d4d4c7",
              width: "75px",
              height: "50px",
              // borderRadius: "50%",
              overflow: "hidden",
              flexShrink: 0,
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                transform: `translate(0, ${rankingPx})`,
                transition: "transform 2s cubic-bezier(0.91, 0.44, 0.25, 0.95)",
                // animation: "move 2s cubic-bezier(0.91, 0.44, 0.25, 0.95) forwards",
                // animationDelay: "1s",
                transitionDelay: "1s",

                // "@keyframes move": {

                // }
              }}
            >
              {rankingArray.map((item, idx) => (
                <Typography
                  sx={{
                    font: "900 24px Pretendard",
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: idx === 1 || idx === 2 || idx === 0 ? "#f63d68" : "#fff",
                    textShadow: "3px 3px 3px rgba(0, 0, 0, 0.2)",
                    transform: "scale(1)",
                    animation: "bigsmall 1s forwards",

                    animationDelay: `5s`,

                    "@keyframes bigsmall": {
                      "0%": { transform: "scale(1)" },
                      "50%": { transform: "scale(1.4)" },
                      "100%": { transform: "scale(1)" },
                    },
                  }}
                  key={item.uid}
                >
                  {idx + 1}
                  {" 등"}
                </Typography>
              ))}
            </Box>
          </Box>

          <Box sx={{ flex: 1, height: "100%", display: "flex", gap: "5px" }}>
            <Box
              sx={{
                // bgcolor: "red",
                flex: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "30px",
                  bgcolor: "rgba(123, 97, 255, .5)",
                  borderRadius: "0 6px 6px 0",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    height: "30px",
                    bgcolor: "rgb(123, 97, 255)",
                    width: `calc(50% + ${percentage}%)`,
                    transition: "width 2s cubic-bezier(0.91, 0.44, 0.25, 0.95)",
                    transitionDelay: "1s",
                    borderRadius: "0 6px 6px 0",
                  }}
                >
                  <Typography
                    sx={{
                      position: "absolute",
                      top: "50%",
                      right: 0,
                      transform: "translate(-10px, -50%)",
                      font: "900 18px Pretendard",
                      color: "#fff",
                      textShadow: "2px 2px 2px rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    {updatingScore}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                width: "60px",
                // bgcolor: "yellow",
                font: "700 16px pretendard",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              +{currentScore}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const QuizResultContainer = () => {
  const { score, rank, imgIdx, name, playingUsers } = useContext(QuizUserContext);
  const [cookies, , removeCookie] = useCookies();

  // useEffect(() => {
  //   if (cookies.uid) {
  //     removeCookie("uid", { path: "/" });
  //   }
  // }, []);

  return (
    <Box
      sx={{
        flex: 1,
        bgcolor: "#fff",
        borderRadius: "10px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <EmojiEventsIcon sx={{ color: "gold", fontSize: "32px" }} />
        <Typography sx={{ font: "800 32px Pretendard" }}>최종 결과</Typography>
      </Box>

      <Box
        sx={{
          // position: "absolute",
          // top: "50%",
          // left: "50%",
          // transform: "translate(-50%, -50%)",
          // bgcolor: "red",
          // borderRadius: "20px",
          // backdropFilter: "blur(10px)",
          // boxShadow:
          // "35px 35px 68px 0px #f5f5f580, inset -4px -4px 16px 0px #f5f5f599, inset 0px 11px 28px 0px #ffffff",
          width: "100%",
          flex: 1,
          position: "relative",
          // aspectRatio: "1/1 auto",
          // display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
          // bgcolor: "red",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            height: "75px",
            width: "100%",
            p: "10px",
            boxSizing: "border-box",
            bgcolor: "#e9d7fe",
            borderRadius: "0 0 10px 10px",
            // border: "2px solid #cdcdcd",
            boxShadow: "0px -5px 5px rgba(0, 0, 0, 0.1)",
            zIndex: 1,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography sx={{ font: "900 24px Pretendard", width: "50px" }}>{rank}</Typography>

          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <img src={Avartar[imgIdx]} style={{ width: "50px" }} />

            <Typography sx={{ font: "900 24px Pretendard" }}>나</Typography>
          </Box>

          <Typography sx={{ font: "700 27px Pretendard", flex: 1, textAlign: "right" }}>
            {score}점
          </Typography>
        </Box>
        <Box sx={{ position: "absolute", height: "100%", width: "100%", overflow: "auto" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "end",
              borderBottom: "1px solid #cdcdcd",
            }}
          >
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: "5px", alignItems: "center" }}
            >
              {playingUsers[1] && (
                <>
                  <img src={Avartar[playingUsers[1].imgIdx]} style={{ width: "80px" }} />

                  <Typography sx={{ font: "900 27px Pretendard" }}>
                    {playingUsers[1].name}
                  </Typography>

                  <Typography sx={{ font: "800 24px Pretendard" }}>
                    {playingUsers[1].score}
                  </Typography>
                </>
              )}

              <Box
                sx={{
                  height: "80px",
                  bgcolor: "rgb(150, 225, 235)",
                  width: "100%",
                  borderRadius: "10px 10px 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    font: "900 36px Pretendard",
                    color: "#fff",
                    textShadow: "3px 3px 2px #00000080",
                  }}
                >
                  2
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{ display: "flex", flexDirection: "column", gap: "5px", alignItems: "center" }}
            >
              <img src={Avartar[playingUsers[0].imgIdx]} style={{ width: "100px" }} />

              <Typography sx={{ font: "900 27px Pretendard" }}>{playingUsers[0].name}</Typography>

              <Typography sx={{ font: "800 24px Pretendard" }}>{playingUsers[0].score}</Typography>

              <Box
                sx={{
                  height: "100px",
                  bgcolor: "rgb(246, 117, 103)",
                  width: "100%",
                  borderRadius: "10px 10px 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    font: "900 36px Pretendard",
                    color: "#fff",
                    textShadow: "3px 3px 2px #00000080",
                  }}
                >
                  1
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "center" }}
            >
              {playingUsers[2] && (
                <>
                  <img src={Avartar[playingUsers[2].imgIdx]} style={{ width: "60px" }} />

                  <Typography sx={{ font: "900 27px Pretendard" }}>
                    {playingUsers[2].name}
                  </Typography>

                  <Typography sx={{ font: "800 24px Pretendard" }}>
                    {playingUsers[2].score}
                  </Typography>
                </>
              )}

              <Box
                sx={{
                  height: "60px",
                  bgcolor: "rgb(177, 202, 255)",
                  width: "100%",
                  borderRadius: "10px 10px 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    font: "900 36px Pretendard",
                    color: "#fff",
                    textShadow: "3px 3px 2px #00000080",
                  }}
                >
                  3
                </Typography>
              </Box>
            </Box>
          </Box>

          {[...playingUsers, { name: "LASTBLANK", uid: "LASTBLANK", imgIdx: 1, score: 1 }]
            .slice(3)
            .map((user, idx) => (
              <Box
                sx={{
                  p: "10px",
                  boxSizing: "border-box",
                  borderBottom: "1px solid #cdcdcd",
                  width: "calc(100% - 7.5px)",
                  display: "flex",
                  alignItems: "center",
                  opacity: user.uid === "LASTBLANK" ? 0 : 1,
                }}
                key={user.uid}
              >
                <Typography sx={{ font: "900 21px Pretendard", color: "#333", width: "50px" }}>
                  {idx + 4}
                </Typography>

                <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                  <img src={Avartar[user.imgIdx]} style={{ width: "50px" }} />

                  <Typography sx={{ font: "800 21px Pretendard", color: "#333" }}>
                    {user.name}
                  </Typography>
                </Box>

                <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
                  <Typography sx={{ font: "800 21px Pretendard", color: "#333" }}>
                    {user.score}점
                  </Typography>
                </Box>
              </Box>
            ))}
          {/* <Box>hello</Box> */}

          {/* <Box sx={{ display: "flex", flexDirection: "column", gap: "5px", alignItems: "center" }}>
            <img src={Avartar[imgIdx]} style={{ width: "clamp(141px, 45.045vw, 200px)" }} />

            <Typography
              sx={{ font: "700 28px Pretendard", "> span": { font: "900 32px Pretendard" } }}
            >
              <span>{name}</span>
              {" 님은"}
            </Typography>

            <Typography sx={{ font: "bold 36px Pretendard" }}>{rank}등 입니다.</Typography>
            <Typography sx={{ font: "600 30px Pretendard" }}>{score}점</Typography>
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
};

export const Outro = () => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pt: "50px",
        px: "20px",
        boxSizing: "border-box",
        gap: "30px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
        <Box
          sx={{
            position: "relative",
            bgcolor: "#f5f5f5",
            p: "10px",
            borderRadius: "10px",
            filter: "drop-shadow(3px 3px 6px #00000058)",
            willChange: "filter",
            "::after": {
              content: '""',
              position: "absolute",
              top: "100%",
              right: "50%",
              transform: "translateX(50%)",
              borderTop: "10px solid #f5f5f5",
              borderLeft: "10px solid transparent",
              borderRight: "10px solid transparent",
              borderBottom: "10px solid transparent",
            },
          }}
        >
          <Typography sx={{ font: "600 20px Pretendard", mb: "10px" }}>
            가장 쉽고 편한 강의 도구 플랫폼은?
          </Typography>
          <Typography sx={{ font: "700 24px Pretendard", textAlign: "right", color: "#c33c3c" }}>
            위드플러스 라이브!
          </Typography>
        </Box>
      </Box>
      <img
        src={mainCharacter3}
        style={{
          width: "40%",
          filter: "drop-shadow(3px 3px 6px #00000058)",
        }}
      />

      <Typography sx={{ font: "600 24px Pretendard", wordBreak: "keep-all", textAlign: "center" }}>
        라이브 퀴즈에 참여해주셔서 감사합니다!
      </Typography>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Button
          sx={{
            borderRadius: "20px",
            p: "15px",
            boxSizing: "border-box",
            font: "600 17px Pretendard",
            fontSize: "clamp(12px, 3.828vw, 17px)",
          }}
          onClick={() => (window.location.href = "https://withplus.live/")}
        >
          다른 위드플러스 라이브 콘텐츠에 참여하기
        </Button>

        {/* <Button>1</Button> */}
      </Box>
    </Box>
  );
};
export default QuizUserPage;
